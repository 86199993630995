
import anime from "animejs";
import rocketIcon from "@/static/icons/Cover_Rocket.svg?raw";
import rocketIconKm from "@/static/icons/Cover_Rocket_km.svg?raw";
import rocketAnimation from "@/assets/animations/rocket-in-space.json";

export default {
  name: "MainPageHeader",
  props: { banners: { type: Array, default: () => [] } },
  data() {
    return {
      rocketIcon,
      rocketIconKm,
      rocketAnimation,
      num: 60,
    };
  },
  computed: {
    vw() {
      if (!process.browser) return 0;
      return Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
    },
    vh() {
      if (!process.browser) return 0;
      return Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
    },
    sliderList() {
      return [
        {
          title: this.$t("landing.section_header.slider_title_1"),
          text: this.$t("landing.section_header.slider_text_1"),
          thumbnail: "/animated/final.gif",
          href: "#",
        },
        {
          title: this.$t("landing.section_header.slider_title_2"),
          text: this.$t("landing.section_header.slider_text_2"),
          thumbnail: "/animated/final.gif",
          href: "#",
        },
      ];
    },
  },
  methods: {
    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars: () => {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 500, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 1;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },
  },
  mounted() {
    if (process.browser) {
      setTimeout(() => {
        this.starryNight();
        this.shootingStars();
      }, 100);
    }
  },
};
